<!-- DrawCanvas.vue -->
<template>
    <div class="loading-text" v-if="isLoading">Loading...</div>
    <div class="error-text" v-else-if="errorText != null">{{ errorText }}</div>

    <div v-else>
      <div v-if="mode == 'DRAW'" class="canvas-container">
        <h3>DRAW!</h3>
        <h5>Time Remaining: {{ (timer / 10).toFixed(1) }}s</h5>
        <label for="playerName">Enter your name!</label>
        <input
          type="text"
          id="playerName"
          v-model="playerName"
          placeholder="Your Name"
          style="margin-bottom: 5px;"
        />
        <canvas ref="canvas" 
          @mousedown="startDrawing" 
          @mousemove="draw" 
          @mouseup="stopDrawing"
          @touchstart="startDrawing"
          @touchmove="draw"
          @touchend="stopDrawing">
        </canvas>
        <div ref="buttonContainer" class="button-container" :disabled="imageSent">
          <button @click="setDrawingColor('black')" style="background-color: black; color: white;">Black</button>
          <button @click="setDrawingColor('red')" style="background-color: red; color: white;">Red</button>
          <button @click="setDrawingColor('yellow')" style="background-color: yellow; color: black;">Yellow</button>
          <button @click="setDrawingColor('green')" style="background-color: green; color: white;">Green</button>
          <button @click="setDrawingColor('blue')" style="background-color: blue; color: white;">Blue</button>
        </div>
        <div ref="buttonContainer" class="button-container">
          <button class="send-image-button" @click="sendImage" :disabled="imageSent">Send Image</button>
          <!-- <button class="reset-button" @click="resetCanvas">Reset Drawing</button> -->
        </div>
      </div>

      <div v-else-if="mode == 'VOTE'">
        <h3>VOTE!</h3>
        <h5>Time Remaining: {{ (timer / 10).toFixed(1) }}s</h5>
        <div class="image-grid">
          <div v-for="(imageData, index) in imageDataArray" :key="index" class="image-container">
            <button class="image-button" @click="vote(imageData)">
              <img :src="imageData.image" alt="Image" />
              <h5>{{ imageData.playerName }}</h5>
            </button>
          </div>    
          <div v-show="showHeart" class="heart-emoji">❤️</div>
        </div>
      </div>

      <div v-else-if="mode == 'BREAK'">
        <h3>WINNER: !</h3>
        <h5>Time Remaining: {{ (timer / 10).toFixed(1) }}s</h5>
        <div v-if="winningSubmission != null">
          <img :src="winningSubmission.image" alt="Image" style="max-width: 400px; max-height: 400px;" />
          <h5>{{ winningSubmission.playerName }}</h5>
          <h5>{{ winningSubmission.voteCount }} Votes!</h5>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import img1 from '@/assets/ollie_1.webp';
  import img2 from '@/assets/ollie_2.webp';
  import img3 from '@/assets/ollie_3.webp';
  import img4 from '@/assets/ollie_4.webp';
  import img5 from '@/assets/ollie_5.webp';
  import img6 from '@/assets/ollie_6.webp';
  import img7 from '@/assets/ollie_7.webp';
  import img8 from '@/assets/ollie_8.webp';
  import img9 from '@/assets/ollie_9.webp';
  import img10 from '@/assets/ollie_10.webp';
  import axios from 'axios';

  const API_URL = 'https://rk1ufu0qdd.execute-api.eu-west-2.amazonaws.com/default/GroobenfulApi'

  export default {
    data() {
      return {
        isLoading: true,
        drawing: false,
        imageSent: false,
        context: null,
        imageData: null,
        drawingColor: 'black',
        imageNumber: null,
        errorText: null,
        mode: "DRAW", // VOTE, BREAK
        timer: 500000,
        timerInterval: null,
        playerName: '',
        sessionHash: '',
        imageDataArray: [],
        winningSubmission: null,
      };
    },
    mounted() {
      this.setUp()
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else if (!this.isLoading) {
          this.timer = 50000
          this.isLoading = true
          this.setUp();
        }
      }, 100);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.resizeCanvas);
    },
    methods: {    
      setUp() {
        this.callApi().then((response) => {
          console.log(response)

          // Assuming the API response contains the image number
          this.imageNumber = response.img;
          this.sessionHash = response.hash
          // Set isLoading to false to hide the loading indicator
          this.isLoading = false;
          this.mode = response.state;
          this.imageDataArray = response.imageDataArray
          console.log(response.imageDataArray)
          this.timer = response.timer/100
          this.winningSubmission = response.winner

          if (this.mode == "DRAW") {
            this.$nextTick(() => {
              this.imageSent = false
              this.context = this.$refs.canvas.getContext('2d');
              this.resetCanvas();
              window.addEventListener('resize', this.resizeCanvas);
              this.resizeCanvas();
              this.loadImages();
            })
          }
        }).catch((error) => {
          console.error('Error loading data from API:', error);
          this.isLoading = false
          this.errorText = 'Error loading data from API: ' + error
          // Handle error
        });
      },
      async callApi() {
        const apiUrl = API_URL
        const response = await axios.get(`${apiUrl}/play`);
        return response.data;
      },
      loadImages() {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        const canvasSize = this.$refs.canvas.width;

        const images = [
          { src: img1, x: canvasSize/2, y: canvasSize/3, width: canvasSize/1.5, height: canvasSize/1.5 },
          { src: img2, x: 0, y: canvasSize/3, width: canvasSize/1.5, height: canvasSize/1.5 },
          { src: img3, x: 0, y: canvasSize/4, width: canvasSize*0.75, height: canvasSize*0.75 },
          { src: img4, x: canvasSize/4, y: canvasSize/2, width: canvasSize*0.5, height: canvasSize*0.5 },
          { src: img5, x: canvasSize*0.25, y: canvasSize*0.25, width: canvasSize*0.75, height: canvasSize*0.75 },
          { src: img6, x: canvasSize/4, y: canvasSize*0.3, width: canvasSize*0.7, height: canvasSize*0.7 },
          { src: img7, x: canvasSize*0.125, y: canvasSize*0.25, width: canvasSize*0.75, height: canvasSize*0.75 },
          { src: img8, x: canvasSize/4, y: canvasSize/2, width: canvasSize/2, height: canvasSize/2 },
          { src: img9, x: 0, y: 0, width: canvasSize, height: canvasSize },
          { src: img10, x: 0, y: 0, width: canvasSize, height: canvasSize },
        ];

        const img = new Image();
        img.onload = () => {
          context.drawImage(
            img,
            images[this.imageNumber].x,
            images[this.imageNumber].y,
            images[this.imageNumber].width,
            images[this.imageNumber].height,
          );
        };
        img.src = images[this.imageNumber].src;
      },
      startDrawing(e) {
        if (!this.imageSent) {
          const { clientX, clientY } = e.type.startsWith('touch') ? e.touches[0] : e;
          this.drawing = true;
          this.context.beginPath();
          this.context.moveTo(clientX - this.$refs.canvas.offsetLeft, clientY - this.$refs.canvas.offsetTop);
        }
      },
      draw(e) {
        if (!this.drawing) return;
        const { clientX, clientY } = e.type.startsWith('touch') ? e.touches[0] : e;

        this.context.strokeStyle = this.drawingColor;
        this.context.lineTo(clientX - this.$refs.canvas.offsetLeft, clientY - this.$refs.canvas.offsetTop);
        this.context.stroke();
      },
      stopDrawing() {
        this.drawing = false;

        const tempCanvas = document.createElement('canvas');
        const tempContext = tempCanvas.getContext('2d');
        tempCanvas.width = 400;
        tempCanvas.height = 400;
        tempContext.drawImage(this.$refs.canvas, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height, 0, 0, 400, 400);
        this.imageData = tempCanvas.toDataURL('image/webp');
      },
      async sendImage() {
        try {
          const apiUrl = API_URL
          const response = await axios.post(`${apiUrl}/submit`, { 
            image: this.imageData,
            playerName: this.playerName,
            gameHash: this.sessionHash,
          });
          this.imageSent = true
          console.log(response.data);
          // Handle the API response as needed
        } catch (error) {
          console.error(error);
          // Handle the error
        }
      },
      setDrawingColor(color) {
        this.drawingColor = color;
      },
      resizeCanvas() {
        const minSize = Math.min(window.innerWidth*0.9, (window.innerHeight - this.$refs.buttonContainer.clientHeight*4)*0.9);
        this.$refs.canvas.width = minSize;
        this.$refs.canvas.height = minSize;
      },
      resetCanvas() {
        this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      },
      async vote(submission) {
        if (!this.showHeart) {
          this.showHeart = true;
          const emojiContainer = document.querySelector('.heart-emoji');
          const { clientX, clientY } = event;
          emojiContainer.style.left = `${clientX-10}px`;
          emojiContainer.style.top = `${clientY-10}px`;
          setTimeout(() => {
            this.showHeart = false;
          }, 1000);

          //TODO: send vote
          const apiUrl = API_URL
          await axios.post(`${apiUrl}/vote`, { 
            gameHash: this.sessionHash,
            submissionId: submission._id
          });
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .canvas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; /* Align the content to the bottom */
    height: 98; /* Set the container height to the full viewport height */
  }

  canvas {
    border: 1px solid #000;
    display: block; /* Ensure the canvas doesn't have extra space below it */
    margin: auto; /* Center the canvas within the container */
  }

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px; /* Optional: Add some margin between canvas and buttons */
}

button {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  font-weight: bold; /* Set the text to bold */
}

button:disabled {
  background-color: #ccc; /* Gray background for disabled buttons */
  border-color: #999; /* Gray border color for disabled buttons */
  color: #666; /* Gray text color for disabled buttons */
  cursor: not-allowed; /* Change cursor for disabled buttons */
}


.send-image-button {
  margin-top: 10px;
  background-color: #4CAF50; /* Green */
  color: white;
  margin-bottom: 10px;
}

.reset-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Align the content to the bottom */
    height: 100vh; /* Set the container height to the full viewport height */
}

.error-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Align the content to the bottom */
    height: 100vh; /* Set the container height to the full viewport height */
}

h3, h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Evenly distribute items along the main axis */
}

.image-container {
  flex-basis: calc(33.33% - 20px); /* Adjust as needed; subtracting padding to create even spacing */
  margin-bottom: 20px; /* Adjust as needed; controls the vertical gap between rows */
}

.image-button {
  border: 1px solid #ccc; /* Thin border */
  background-color: #fff; /* Background color */
  padding: 5px; /* Adjust padding as needed */
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Add transition effect */
}

.image-button:hover {
  border-color: #007bff; /* Border color on hover */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on hover */
}

img {
  width: 100%;
  height: auto;
}

.heart-emoji {
  position: absolute;
  font-size: 24px;
  color: #ff0000; /* Heart emoji color */
  pointer-events: none; /* Allow clicks to go through the emoji */
  animation: fadeOut 1s forwards; /* Animation for fading out */
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
  </style>
  