<template>
  <div id="app">
    <DrawCanvas />
  </div></template>
<script>

import DrawCanvas from './components/DrawCanvas.vue';

export default {
  name: 'App',
  components: {
    DrawCanvas,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
</style>
